import { Guest } from './GuestTypes';

const reEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const REQUIRED = 'REQUIRED';

export const validateGuest = (values: Guest) => {
  const errors: { [key: string]: string } = {};

  if (!values.id && !values.free_type) {
    errors.free_type = REQUIRED;
  }
  if (!values.first_name) {
    errors.first_name = REQUIRED;
  }
  if (!values.last_name) {
    errors.last_name = REQUIRED;
  }
  if (!values.born_at) {
    errors.born_at = REQUIRED;
  }
  if (!values.sex) {
    errors.sex = REQUIRED;
  }
  if (!values.email || !reEmail.test(values.email)) {
    errors.email = REQUIRED;
  }
  if (!values.country) {
    errors.country = REQUIRED;
  }
  if (!values.city) {
    errors.city = REQUIRED;
  }

  return errors;
};
