import { Moment } from 'moment';

import { Event } from '../events/EventTypes';

export interface DashboardRegDate {
  date: Moment;
  count: number;
  total: number;
}

export interface AmountData {
  byPrice: { amount: number; count: number; total: number }[];
  total: number;
}

export enum GuestFreeType {
  Cash = 1,
  Young = 2,
  Social = 3,
  BlackBand = 4,
}

export interface DashboardData {
  age: { age: number; count: number }[];
  cities: { name: string; count: number }[];
  countries: { name: string; count: number }[];
  free: {
    total: number;
    withoutType: number;
    byType: Record<GuestFreeType, number>;
  };
  groups: { 1: number; 2: number };
  regDate: DashboardRegDate[];
  regMonth: DashboardRegDate[];
  sex: {
    male: number;
    female: number;
  };
  total: number;
  events: Pick<
    Event,
    'id' | 'starts_at' | 'max_visitors' | 'title' | 'guests'
  >[];
  staff: {
    guests: number;
    linked: number;
    listTotal: number;
    staffGuests: number;
  };
  amount: AmountData;
  staffAmount: AmountData;
  accommodation: number;
  nextYear: number;
  redemptions: number;
  donations: number;
}
