import { Moment } from 'moment';
import { useMemo } from 'react';

import { useAuth } from '../components/AuthProvider';
import { useAPI } from '../services/api';

export enum Sex {
  Male = 2,
  Female = 1,
}

export interface Guest {
  id: number;
  version: number;
  created_at: Moment;
  updated_at: Moment;
  creator_id: number | null;
  updater_id: number | null;

  first_name: string;
  last_name: string;
  born_at: Moment;
  sex: Sex;
  phone: string;
  email: string;
  country: string;
  city: string;
  church: string;
  online: boolean;

  amount: number;
  order_id: number;
  paid_at: Moment | null;
  sent_at: Moment | null;
  sent_to_user_at: Moment | null;
  qr_code_shown_at: Moment | null;

  pass_id: number | null;
  qr_code_id: number | null;
  accommodation: boolean;
  group: 1 | 2 | 3 | null;

  redeemedAt: Moment | null;
  is_staff: boolean;
  free_type: 1 | 2 | 3 | 4 | null;
}

export interface RedemptionData {
  id: number;
  redeemedAt: Moment;
  service: string;
  user: {
    id: number;
    name: string;
  };
}

export interface HistoryData {
  id: number;
  updated_at: Moment;
  user: string;
  column: string;
  value: string;
}

export const guestColumnNames: Record<string, string> = {
  version: 'Версия',
  born_at: 'Дата рождения',
  church: 'Церковь',
  city: 'Город',
  country: 'Страна',
  email: 'Эл. почта',
  first_name: 'Имя',
  last_name: 'Фамилия',
  phone: 'Телефон',
  sex: 'Пол',
  group: 'Конференция',
  accommodation: 'Расселение',
  online: 'Онлайн',

  title: 'Название',
  location: 'Место',
  enabled: 'Включить',
  starts_at: 'Начало',
  ends_at: 'Конец',
  max_visitors: 'Макс. посетителей',
};

export const useGroupOptions = () => {
  const { is } = useAuth();
  const groups = useAPI<
    {
      id: number;
      title: string;
      dates: string;
    }[]
  >('/groups');
  const groupOptions = useMemo(
    () => [
      ...(groups.data || []).map((g) => ({
        value: `${g.id}`,
        label: g.dates,
      })),
      {
        value: '3',
        label: 'Волонтёр',
      },
      ...(is.editor
        ? [
            {
              value: '0',
              label: 'Онлайн',
            },
          ]
        : []),
    ],
    [groups, is.editor]
  );

  return groupOptions;
};
