import { useCallback } from 'react';
import { Field, FormRenderProps } from 'react-final-form';

import { Checkbox } from '../components/forms/Checkbox';
import { DateInput } from '../components/forms/DateInput';
import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { Radio } from '../components/forms/Radio';
import { inputError } from '../components/forms/errors';
import { Guest } from './GuestTypes';

export const GuestForm: React.FC<
  FormRenderProps<Guest & { transfer: boolean }>
> = ({ handleSubmit, submitting, form: { change }, values }) => {
  const creating = !values.id;

  const setTransfer = useCallback(
    (v: boolean) => () => {
      change('transfer', v);
    },
    [change]
  );

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <FormLimiter>
        {(creating || values.amount === 0) && (
          <Radio
            name="free_type"
            label="Тип гостя"
            options={[
              { value: '1', label: 'Наличные' },
              { value: '2', label: 'До 12 лет' },
              { value: '3', label: 'Детский дом' },
              { value: '4', label: 'Вечный браслет' },
            ]}
          />
        )}

        <Input name="first_name" maxLength={50} label="Имя" cols={4} />
        <Input name="last_name" maxLength={50} label="Фамилия" cols={4} />

        {!creating && <Checkbox name="online" label="Онлайн" />}
        {!creating && <Checkbox name="is_staff" label="Волонтёр" />}

        <div className="form-group row">
          <label htmlFor="born_at" className="col-sm-2 col-form-label">
            Дата рождения
          </label>
          <div className="col-sm-4">
            <Field name="born_at">
              {({ input, meta }) => (
                <div>
                  <DateInput
                    className={inputError(meta) ? 'is-invalid' : undefined}
                    {...input}
                  />
                  {inputError(meta) && (
                    <div className="invalid-feedback">
                      Поле обязательно для заполнения
                    </div>
                  )}
                </div>
              )}
            </Field>
          </div>
        </div>

        <Radio
          name="sex"
          label="Пол"
          options={[
            { value: '2', label: 'мужской' },
            { value: '1', label: 'женский' },
          ]}
        />

        <Input name="phone" maxLength={20} label="Телефон" cols={4} />
        <Input name="email" maxLength={50} label="Эл. почта" cols={4} />
        <Input name="country" maxLength={50} label="Страна" cols={4} />
        <Input name="city" maxLength={50} label="Город" cols={4} />
        <Input name="church" maxLength={50} label="Церковь" />
      </FormLimiter>

      <FormSubmitContainer>
        <button
          className="btn btn-lg btn-primary mr-5"
          type="submit"
          onClick={setTransfer(false)}
          disabled={submitting}
        >
          {creating ? 'Создать' : 'Сохранить'}
        </button>
        {!creating && (
          <button
            className="btn btn-lg btn-warning"
            type="submit"
            onClick={setTransfer(true)}
            disabled={submitting}
          >
            Сохранить и выдать новый QR-код
          </button>
        )}
        {!creating && (
          <div className="mt-4 text-muted small">
            Выдавайте новый QR-код, когда один человек передает регистрацию
            другому. Старый код перестанет работать.
          </div>
        )}
      </FormSubmitContainer>
    </Form>
  );
};
