import classnames from 'classnames';
import moment, { Moment } from 'moment';
import { useState, useEffect, useCallback, useMemo } from 'react';
import InputMask from 'react-input-mask';

const FORMAT = 'DD.MM.YYYY';

type DateTimeInputProps = {
  active?: boolean;
  className?: string;
  onChange: (v: Moment | undefined) => void;
  value: Moment | undefined;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const DateInput: React.FC<DateTimeInputProps> = ({
  active = false,
  className,
  onChange,
  onFocus,
  onBlur,
  value,
  ...rest
}) => {
  const [local, setLocal] = useState('');
  const [localParsed, setLocalParsed] = useState<Moment | undefined>();

  useEffect(() => {
    setLocal(value ? value.format(FORMAT) : '');
    setLocalParsed(value);
  }, [value]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      setLocal(v);
      if (v) {
        const t = moment(v, FORMAT);
        setLocalParsed(t || undefined);
        if (t.format(FORMAT) === v) {
          onChange(t);
        }
      } else {
        onChange(undefined);
        setLocalParsed(undefined);
      }
    },
    [onChange]
  );

  const isInvalid = useMemo(() => {
    if ((!local && !localParsed) || active) {
      return false;
    }
    return !localParsed || localParsed.format(FORMAT) !== local;
  }, [active, local, localParsed]);

  const isValid = useMemo(
    () =>
      active && local && localParsed && localParsed.format(FORMAT) === local,
    [active, local, localParsed]
  );

  return (
    <>
      <InputMask
        className={classnames(
          'form-control',
          className,
          isInvalid && 'is-invalid',
          isValid && 'is-valid'
        )}
        mask="99.99.9999"
        value={local}
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      />
    </>
  );
};
