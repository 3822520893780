import { PropsWithChildren } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface QrCodeModalProps {
  show?: boolean;
  title?: string;
  toggle: () => void;
  url?: string;
}

export const QrCodeModal: React.FC<PropsWithChildren<QrCodeModalProps>> = ({
  children,
  show,
  title,
  toggle,
  url,
}) => (
  <Modal isOpen={show} toggle={toggle} size="sm">
    <ModalHeader toggle={toggle}>{title}</ModalHeader>
    <ModalBody>
      {children}
      {url && show && (
        <div>
          <img style={{ width: 'auto' }} alt="QR-код" src={url} />
        </div>
      )}
    </ModalBody>
    <ModalFooter>
      <button
        className="btn btn-primary btn-block"
        onClick={toggle}
        type="button"
      >
        Закрыть
      </button>
    </ModalFooter>
  </Modal>
);
