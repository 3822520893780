import { useState, useEffect, useRef } from 'react';
import io, { Socket } from 'socket.io-client';

import { dateReviver } from '../services/api';
import { DashboardData } from './DashboardTypes';

interface DashboardOptions {
  online: boolean;
  nextYear: boolean;
  redeemed: boolean;
}

export const useDashboard = (options?: Partial<DashboardOptions>) => {
  const [data, setData] = useState<DashboardData | undefined>();
  const online = !!options?.online;
  const nextYear = !!options?.nextYear;
  const redeemed = !!options?.redeemed;

  const ws = useRef<Socket>();
  const params = useRef<DashboardOptions>({ online, nextYear, redeemed });

  useEffect(() => {
    ws.current = io('/dashboard', {
      transports: [process.env.NODE_ENV === 'development' ? 'polling' : 'websocket'],
    });
    ws.current.on('connect', () => {
      ws.current?.emit('options', params.current);
    });
    ws.current.on('data', (payload: DashboardData) => {
      setData(JSON.parse(JSON.stringify(payload), dateReviver));
    });
    return () => {
      ws.current?.close();
      ws.current = undefined;
    };
  }, []);

  useEffect(() => {
    setData(undefined);
    params.current = { online, nextYear, redeemed };
    if (ws.current?.connected) {
      ws.current.emit('options', params.current);
    }
  }, [online, nextYear, redeemed]);

  return data;
};
