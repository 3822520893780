import { PropsWithChildren } from 'react';

import { FormLimiter } from './FormLimiter';
import './FormSubmitContainer.css';

interface FormSubmitContainerProps {
  offsetCols?: number;
}

export const FormSubmitContainer: React.FC<
  PropsWithChildren<FormSubmitContainerProps>
> = ({ children, offsetCols = 2 }) => (
  <div className="form-submit-container">
    <FormLimiter>
      <div className="form-group row">
        <div className={`col-sm-${12 - offsetCols} offset-sm-${offsetCols}`}>
          {children}
        </div>
      </div>
    </FormLimiter>
  </div>
);
