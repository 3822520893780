import classNames from 'classnames';
import moment from 'moment';

import { useAPI } from '../../services/api';

interface FoodTableRowDay {
  day: number;
  quantity: number;
  redeemedAt: Date | null;
}

interface FoodTableRow {
  type: string;
  leader: string;
  registered: boolean;
  days: FoodTableRowDay[];
}

interface FoodTableData {
  days: string[];
  rows: FoodTableRow[];
  total: number[];
  redeemed: number[];
}

export const Food: React.FC = () => {
  const { data } = useAPI<FoodTableData>('/food');
  return (
    <>
      <h2>Питание волонтёров</h2>

      {data && (
        <table className="table table-sm table-auto tab-nums">
          <thead>
            <tr>
              <th>Отдел</th>
              <th>Лидер</th>
              {data.days.map((d) => (
                <th className="text-right" key={d}>
                  {moment(d).format('ddd')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((r) => (
              <tr key={r.type}>
                <td>{r.type}</td>
                <td className={r.registered ? undefined : 'text-danger'}>
                  {r.leader}
                  {!r.registered && (
                    <span title="Не зарегистрирован(а)">*</span>
                  )}
                </td>
                {r.days.map((d) => (
                  <td
                    className={classNames(
                      'text-right',
                      d.redeemedAt && 'bg-success'
                    )}
                    key={d.day}
                  >
                    {d.quantity}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Всего</th>
              <th className="text-right">
                {data.total.reduce((s, v) => s + v, 0)}
              </th>
              {data.total.map((v, i) => (
                <th className="text-right" key={i}>
                  {v}
                </th>
              ))}
            </tr>
            <tr>
              <th>Забрали</th>
              <th className="text-right">
                {data.redeemed.reduce((s, v) => s + v, 0)}
              </th>
              {data.redeemed.map((v, i) => (
                <th className="text-right" key={i}>
                  {v}
                </th>
              ))}
            </tr>
          </tfoot>
        </table>
      )}
    </>
  );
};
