import { NavLink as Link } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import { config } from '../config';
import { useToggle } from '../services/useToggle';
import { useAuth } from './AuthProvider';

export const Header: React.FC = () => {
  const { deImpersonate, is, logout, user } = useAuth();
  const [isOpen, toggle] = useToggle();
  return (
    <Navbar dark color="dark" expand="md" className="mb-3">
      <NavbarBrand exact tag={Link} to="/">
        {config.app.name}
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {(is.editor || is.reception) && (
            <NavItem>
              <NavLink tag={Link} to="/guests">
                Регистрации
              </NavLink>
            </NavItem>
          )}
          {(is.editor || is.reception) && (
            <NavItem>
              <NavLink tag={Link} to="/staff">
                Волонтёры
              </NavLink>
            </NavItem>
          )}
          {(is.editor || is.usher) && (
            <NavItem>
              <NavLink tag={Link} to="/events">
                События
              </NavLink>
            </NavItem>
          )}
          {is.editor && (
            <NavItem>
              <NavLink tag={Link} to="/email-templates">
                Письма
              </NavLink>
            </NavItem>
          )}
          {is.editor && (
            <NavItem>
              <NavLink tag={Link} to="/food">
                Питание
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Collapse>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <span className="d-none d-sm-inline">{user && user.name}</span>
          </DropdownToggle>
          <DropdownMenu right>
            {user?.impersonator && (
              <DropdownItem onClick={deImpersonate}>
                Войти как {user.impersonator.name}
              </DropdownItem>
            )}
            {is.admin && (
              <DropdownItem tag={Link} to="/users">
                Пользователи
              </DropdownItem>
            )}
            {(is.reception || is.usher) && (
              <DropdownItem tag={Link} to="/app-users">
                Пользователи приложения
              </DropdownItem>
            )}
            {(is.admin || is.reception) && <DropdownItem divider />}
            <DropdownItem onClick={logout}>Выход</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};
