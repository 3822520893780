import { Form } from 'react-final-form';
import { FaQrcode } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';

import { QrCodeModal } from '../appUsers/QrCodeModal';
import { useAuth } from '../components/AuthProvider';
import { EntityHeader } from '../components/EntityHeader';
import { useItem } from '../services/useItem';
import { useToggle } from '../services/useToggle';
import { GuestForm } from './GuestForm';
import { Guest as GuestType } from './GuestTypes';
import { ItemHistory } from './ItemHistory';
import { RedemptionModal } from './RedemptionModal';
import { SendEmailDropdown } from './SendEmailDropdown';
import { validateGuest } from './validateGuest';

export const Guest: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const { is } = useAuth();
  const data = useItem<GuestType>(match.params.id, '/guests', history);
  const { creating, found, item, save } = data;
  const [showCode, toggleCode] = useToggle(false);
  const [showRedemption, toggleRedemption] = useToggle(false);

  return (
    <>
      <EntityHeader
        data={data}
        del={false}
        getTitle={(item) => (item.id ? 'Регистрация' : 'Без оплаты')}
        listLabel="Регистрации"
        listUrl="/guests"
      >
        {found && item && item.id && <ItemHistory item={item} url="guests" />}
        {found && item && item.id && (
          <button
            className="btn btn-primary btn-sm"
            onClick={toggleCode}
            type="button"
          >
            <FaQrcode />
          </button>
        )}
        {found && item && item.id && <SendEmailDropdown id={item.id} />}
      </EntityHeader>

      <QrCodeModal
        title={[item?.first_name, item?.last_name].filter(Boolean).join(' ')}
        show={showCode}
        toggle={toggleCode}
        url={item && `/api/guests/${item.id}/qr-code.svg`}
      >
        <div className="mb-3">{item?.order_id}</div>
      </QrCodeModal>

      {item && (
        <RedemptionModal
          title={[item?.first_name, item?.last_name].filter(Boolean).join(' ')}
          show={showRedemption}
          toggle={toggleRedemption}
          id={item.id}
        >
          <div>{item.order_id}</div>
        </RedemptionModal>
      )}

      {found && item && item.id && (
        <div className="mb-1">
          Номер платежа: {item.order_id}, дата и время:{' '}
          {item.paid_at?.format('DD.MM.YYYY HH:mm:ss')}
          {is.editor && <>, сумма: {item.amount} ₽</>}
        </div>
      )}

      {found && item && !creating && (
        <div className="mb-3">
          {item.redeemedAt ? (
            <>
              ✅ Регистрация подтверждена{' '}
              {item.redeemedAt.format('DD.MM.YYYY HH:mm:ss')}{' '}
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={toggleRedemption}
                type="button"
              >
                ...
              </button>
            </>
          ) : (
            <span className="text-muted">Регистрация не подтверждена</span>
          )}
        </div>
      )}

      {found && item && (
        <Form
          initialValues={item}
          onSubmit={save}
          render={GuestForm}
          validate={validateGuest}
        />
      )}
    </>
  );
};
