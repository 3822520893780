import { MouseEventHandler, useCallback, useMemo, useState } from 'react';

import './Chart.css';

interface ChartProps<T extends any> {
  color?: string;
  height?: number | string;
  label?: (v: T) => string;
  keyProp: string;
  rows: T[];
  showLabels?: boolean;
  valueProp: string;
  width?: number | string;
}

export const Chart: React.FC<ChartProps<any>> = ({
  color = 'rgba(0,0,0,0.4)',
  height = 100,
  label,
  keyProp,
  rows,
  showLabels = false,
  valueProp,
  width = 600,
}) => {
  const [tooltip, setTooltip] = useState('');

  const onHover = useCallback<MouseEventHandler>(
    (e) => {
      const i = parseInt(e.currentTarget.getAttribute('data-i') || '', 10);
      setTooltip(
        label ? label(rows[i]) : `${rows[i][keyProp]}: ${rows[i][valueProp]}`
      );
    },
    [keyProp, label, rows, valueProp]
  );
  const mouseLeave = useCallback(() => {
    setTooltip('');
  }, []);

  const maxValue = useMemo(
    () => Math.max(...rows.map((r) => r[valueProp])),
    [rows, valueProp]
  );

  const xstep = (1 / rows.length) * 100;
  return (
    <svg width={width} height={height} className="chart">
      {showLabels &&
        rows.map((row, i) => (
          <text
            key={row[keyProp]}
            className="label-key"
            x={`${i * xstep}%`}
            y="99%"
          >
            {label ? label(row) : row[keyProp]}
          </text>
        ))}
      {rows.map((row, i) => (
        <g key={row[keyProp]}>
          <rect
            height="92%"
            style={{ fill: 'transparent' }}
            width={`${100 / rows.length}%`}
            x={`${i * xstep}%`}
            y={0}
            onMouseOver={onHover}
            onMouseLeave={mouseLeave}
            data-i={i}
          />
          <rect
            className="bar"
            height={`${(row[valueProp] / maxValue) * 92}%`}
            style={{ fill: color }}
            width={`${100 / rows.length - 0.2}%`}
            x={`${i * xstep}%`}
            y={`${92 - (row[valueProp] / maxValue) * 92}%`}
          />
        </g>
      ))}
      <text x={0} y={20} style={{ fontSize: 14 }}>
        {tooltip}
      </text>
    </svg>
  );
};
