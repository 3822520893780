import { Field } from 'react-final-form';

import { inputError } from './errors';

interface RadioProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
}

export const Radio: React.FC<RadioProps> = ({ label, name, options }) => (
  <Field name={name}>
    {({ meta }) => {
      const error = inputError(meta);
      return (
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">{label}</label>
          <div className="col-sm-10" style={{ paddingTop: 7 }}>
            {options.map((o) => (
              <Field
                key={o.value}
                name={name}
                type="radio"
                value={parseInt(o.value)}
                parse={(value: any, name: string) => parseInt(value)}
              >
                {({ input }) => (
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`${name}_${o.value}`}
                      className="custom-control-input"
                      {...input}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${name}_${o.value}`}
                    >
                      {o.label}
                    </label>
                  </div>
                )}
              </Field>
            ))}
            {error && <div className="is-invalid" />}
            {error && (
              <div className="invalid-feedback">Выберите один из вариантов</div>
            )}
          </div>
        </div>
      );
    }}
  </Field>
);
